/**
 * Created by amotis on 29/05/2020.
 */
angular
    .module('annexaApp')
    .component('annexaCronGen', {
        templateUrl: './components/querys/annexa-cron-gen/annexa-cron-gen.html',
        controller: ['$scope', function ($scope) {
        	$scope.cronWarnings = [];
        	this.$onInit = function () {
        		if(this && this.cronOptions && this.cronOptions.hideMinutesTab){
        			$scope.cronWarnings.push({ msg: 'global.commonAdmin.modal.cron.warning' });
        		}else{
        			$scope.cronWarnings.push({ msg: 'global.commonAdmin.modal.cron.warningMinutes' });
        		}
            }
        	
        }],
        bindings: {
        	cronExpression: '=',
        	cronOptions: '=',
        	isCronDisabled: '='
        }
    })